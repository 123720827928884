


























































































































































































































































































































































































































































































































































































































































import Navbar from "@/components/Navbar.vue";
import FooterVue from "@/components/FooterVue.vue";
import Vue from "vue";
export default Vue.extend({
  name: "Home",
  components: {
    Navbar,
    FooterVue,
  },
  data: () => ({
    error: "",
    reveal: false,
  }),
  props: {
    merchant: {},
  },
  methods: {
    onFloatCreated(f: any) {
      this.$emit("float:created", f);
    },
  },
});
