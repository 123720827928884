import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/preguntas",
    name: "Preguntas",
    component: () => import("../views/Preguntas.vue"),
  },
  {
    path: "/terminos",
    name: "Terminos",
    component: () => import("../views/Terminos.vue"),
  },
  {
    path: "/testimonios",
    name: "Testimonios",
    component: () => import("../views/Testimonios.vue"),
  },
  {
    path: "/tarifas",
    name: "Tariff",
    component: () => import("../views/Tariff.vue"),
  },
  {
    path: "/anuncios",
    name: "Anuncios",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/Anuncios.vue"),
  }
];

const router = new VueRouter({
  routes,
  mode: "history",
  base: '/website',
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    }
  },
});


// Meta Handling
router.beforeEach((to, from, next) => {
  if (to.query.token) {
    localStorage.setItem("session", to.query.token as string);
    delete to.query.token;
    return next({
      path: to.path,
      query: to.query
    });
  }

  const jwt = localStorage.getItem("session");
  // If the destination route requires authentication, and we have no JWT, redirect to home.
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!jwt) {
      next({
        path: "/",
        params: {  },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
