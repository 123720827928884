















import Vue from "vue";
const AOS = require('aos'); 
import 'aos/dist/aos.css';
import Vuetify from "vuetify";
import VueClipboard from "vue-clipboard2";
import Navbar from './components/Navbar.vue'
const VueScrollTo = require('vue-scrollto')

 
import "@/assets/tailwind.css";
import { clearCache, formatCurrency } from "./utils";
import FooterVue from "./components/FooterVue.vue";

export default Vue.extend({
  name: "App",
  components: { 
    Navbar,
    FooterVue
  },
  data: () => ({
    link: undefined,
    showHeader: true,
    authenticated: false,
    merchant: {},
  }),
  watch: {
    $route(to, from) {
    
    },
  },
  async mounted() {
     await this.updateWebsite();
  },
  methods: {
     async updateWebsite() {
      const siteVersion = localStorage.getItem("site-version");
      const resp = await fetch(`${process.env.VUE_APP_API_URL}/misc/versions`, {
        headers: {
          "Content-Type": "application/json",
          [process.env.VUE_APP_API_HEADER]: process.env.VUE_APP_API_KEY || "",
        },
      });

      const { ok, data, errors, errorMessage } = await resp.json();
      if (ok) {
        const latestVersion = data.website;
        if (latestVersion) {
          if (siteVersion) {
            if (siteVersion !== latestVersion) await clearCache(latestVersion);
          } else await clearCache(latestVersion);
        }
      }
    },
  },
});

AOS.init();

Vue.filter("money", formatCurrency);

Vue.use(Vuetify);
Vue.use(VueScrollTo);

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
