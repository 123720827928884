















































































































import Vue from "vue";
export default Vue.extend({
  name: "Navbar",
  components: {},
  data: () => ({
    error: "",
  }),
  props: {
    merchant: {},
  },
  methods: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
});
