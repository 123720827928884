export function renewSession(resp: Response) {
  const apiHeader = resp.headers.get(process.env.VUE_APP_API_HEADER);
  if (apiHeader) {
    localStorage.setItem("session", apiHeader);
  }
}

export function createFloat(opts: { text: string; type: string; duration: number }) {
  const float: any = {};
  if (opts.text) float.text = opts.text;
  if (opts.type) float.type = opts.type;
  if (opts.duration) float.duration = opts.duration;
  return float;
}

export function formatCurrency(value: number) {
  const formatter = new Intl.NumberFormat("es-DO", {
    style: "currency",
    currency: "DOP",
  });
  return formatter.format(value);
}

export async function clearCache(latestVersion: any) {
  var cookies = document.cookie.split("; ");
  for (var c = 0; c < cookies.length; c++) {
    var d = window.location.hostname.split(".");
    while (d.length > 0) {
      var cookieBase =
        encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
        "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
        d.join(".") +
        " ;path=";
      var p = location.pathname.split("/");
      document.cookie = cookieBase + "/";
      while (p.length > 0) {
        document.cookie = cookieBase + p.join("/");
        p.pop();
      }
      d.shift();
    }
    if ("caches" in window) {
      const names = await caches.keys();
      await Promise.all(names.map(caches.delete.bind(caches)));
    }
    localStorage.setItem("site-version", latestVersion);
    window.location.reload();
    return null;
  }
}