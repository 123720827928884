





























































































































import Vue from "vue";
export default Vue.extend({
  name: "Footer",
  components: {},
  data: () => ({
    error: "",
  }),
  props: {
    merchant: {},
  },
  methods: {},
});
